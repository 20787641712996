import BaseLayout from "src/layouts/BaseLayout";
import { useEffect } from "react";
import { useRouter } from "next/router";

function Overview() {
  const router = useRouter();

  useEffect(() => {
    const localStorageItem = localStorage.getItem('dts-should-redirect');

    router.prefetch("/dashboard");
    if (localStorageItem) router.push('/dashboard')
    else router.replace('/auth/login')
  }, [router]);

  return <></>;
}

export default Overview;

Overview.getLayout = function getLayout(page) {
  return <BaseLayout>{page}</BaseLayout>;
};
